import React from 'react';
import { graphql } from 'gatsby';
import { PageHero, PageWithSubNav, PlatformTabs } from 'docComponents';
import {
  AndroidTab,
  AppleTab,
  OtherTab,
  ReactNativeTab,
  WebTab,
} from './platforms/_index.js';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="code"
      title="Button"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Buttons" tierThree="Button" />

      <PlatformTabs>
        <WebTab type="web" />
        <ReactNativeTab type="reactnative" />
        <AndroidTab data={data} type="android" />
        <AppleTab type="apple" />
        <OtherTab data={data} type="other" />
      </PlatformTabs>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query buttonsColorAndroid {
    allColorsYaml(filter: { type: { eq: "Buttons" } }) {
      edges {
        node {
          type
          light_environment_colors {
            value
            hex_value
            android_var
            common_name
          }
          dark_environment_colors {
            value
            hex_value
            android_var
            common_name
          }
        }
      }
    }
  }
`;
