import React from 'react';
import { Link } from 'hudl-uniform-ui-components';
import {
  ColorDataTableCode,
  PlatformTab,
  PlatformTabEmptyState,
  Section,
} from 'docComponents';

const AndroidTab = ({ data }) => {
  return (
    <PlatformTab>
      <PlatformTabEmptyState platform="android">
        For now, you can follow{' '}
        <Link href="https://material.io/guidelines/components/buttons.html">
          Material guidelines
        </Link>{' '}
        with the Uniform color variables below.
      </PlatformTabEmptyState>

      <Section title="Light Environment">
        <ColorDataTableCode
          env="light"
          colorsType="light"
          data={data}
          platform="android"
        />
      </Section>

      <Section title="Dark Environment">
        <ColorDataTableCode
          env="dark"
          colorsType="dark"
          data={data}
          platform="android"
        />
      </Section>
    </PlatformTab>
  );
};

export default AndroidTab;
