import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';
import { Button } from 'hudl-rn-uniform-ui';

const snippet = require('raw-loader!../../../../../data/snippets/rn-button.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Button: Button }}
        code={snippet}
        platform="react-native"
        gitHubLink="buttons/button"
        layout="rn"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="type" types={['oneOf', 'string']}>
            <Text>Determines what type appears on the button.</Text>
            <List type="unordered">
              <li>
                <code>primary</code> (default)
              </li>
              <li>
                <code>secondary</code>
              </li>
              <li>
                <code>destroy</code>
              </li>
              <li>
                <code>confirm</code>
              </li>
              <li>
                <code>subtle</code>
              </li>
              <li>
                <code>cancel</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="style" types={['oneOf', 'string']}>
            <Text>Determines the button style.</Text>
            <List type="unordered">
              <li>
                <code>standard</code> (default)
              </li>
              <li>
                <code>minimal</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="size" types={['oneOf', 'string']}>
            <Text>Determines the button’s size.</Text>
            <List type="unordered">
              <li>
                <code>xsmall</code>
              </li>
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="isBlock" types={['bool']}>
            <Text>
              Dictates whether or not the width fills 100% of the button’s
              container.
            </Text>
          </PropListItem>

          <PropListItem name="status" types={['oneOf', 'string']}>
            <Text>Adds a status indicator to the button.</Text>
            <List type="unordered">
              <li>
                <code>spinning</code> - Adds a spinner next to the text.
              </li>
              <li>
                <code>success</code> - Makes the button green and adds a
                checkmark next to the text.
              </li>
              <li>
                <code>failure</code> - Makes the button red and adds an X next
                to the text.
              </li>
            </List>
          </PropListItem>

          <PropListItem name="icon" types={['string']}>
            <Text>Adds an icon element to the button.</Text>
          </PropListItem>

          <PropListItem name="text" types={['string']}>
            <Text>The text that appears in the button.</Text>
          </PropListItem>

          <PropListItem name="disabled" types={['bool']}>
            <Text>Adds a disabled attribute if true.</Text>
          </PropListItem>

          <PropListItem name="space" types={['oneOf', 'string']}>
            <Text>
              Any of the{' '}
              <Link href="/resources/code-variables/space?reactnative">
                space variables
              </Link>
              .
            </Text>
          </PropListItem>

          <PropListItem name="theme" types={['oneOf', 'string']}>
            <Text>Determines the theme of the button.</Text>
            <List type="unordered">
              <li>
                <code>light</code> (default)
              </li>
              <li>
                <code>dark</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="testID" types={['string']}>
            <Text>
              A unique string to control this button in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="accessibilityLabel" types={['string']}>
            <Text>
              Overrides the text that's read by the screen reader when the user interacts with the button.
            </Text>
          </PropListItem>

          <PropListItem name="onPress" types={['func']}>
            <Text>Responds to the button press event.</Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
