import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, Button } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../../data/snippets/button.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Button: Button }}
        code={snippet}
        platform="react"
        gitHubLink="buttons/button"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="buttonType" types={['oneOf', 'string']}>
            <Text>
              Determines the button’s HTML button type. Read more on the{' '}
              <Link href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button">
                MDN site
              </Link>
              .
            </Text>
            <List type="unordered">
              <li>
                <code>button</code> (default)
              </li>
              <li>
                <code>reset</code>
              </li>
              <li>
                <code>submit</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="type" types={['oneOf', 'string']}>
            <Text>Determines what type appears on the button.</Text>
            <List type="unordered">
              <li>
                <code>primary</code> (default)
              </li>
              <li>
                <code>secondary</code>
              </li>
              <li>
                <code>destroy</code>
              </li>
              <li>
                <code>confirm</code>
              </li>
              <li>
                <code>subtle</code>
              </li>
              <li>
                <code>cancel</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="style" types={['oneOf', 'string']}>
            <Text>Determines the button style.</Text>
            <List type="unordered">
              <li>
                <code>standard</code> (default)
              </li>
              <li>
                <code>minimal</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="size" types={['oneOf', 'string']}>
            <Text>Determines the button’s size.</Text>
            <List type="unordered">
              <li>
                <code>xsmall</code>
              </li>
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="isBlock" types={['bool']}>
            <Text>
              Dictates whether or not the width fills 100% of the button’s
              container.
            </Text>
          </PropListItem>

          <PropListItem name="collapsePadding" types={['oneOf', 'string']}>
            <Text>
              Collapses side padding of minimal buttons for better alignment.
            </Text>
            <List type="unordered">
              <li>
                <code>left</code>
              </li>
              <li>
                <code>right</code>
              </li>
              <li>
                <code>horizontal</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="status" types={['oneOf', 'string']}>
            <Text>Adds a status indicator to the button.</Text>
            <List type="unordered">
              <li>
                <code>spinning</code> - Adds a spinner next to the text.
              </li>
              <li>
                <code>success</code> - Makes the button green and adds a
                checkmark next to the text.
              </li>
              <li>
                <code>failure</code> - Makes the button red and adds an X next
                to the text.
              </li>
            </List>
          </PropListItem>

          <PropListItem name="icon" types={['element', 'string']}>
            <Text>
              Adds an icon element to the button. You can either pass an Icon
              element or just the name of the icon.
            </Text>
          </PropListItem>

          <PropListItem name="text" types={['string']}>
            <Text>
              The text that appears on the button. Can also be passed in as
              children of the component.
            </Text>
          </PropListItem>

          <PropListItem name="disabled" types={['bool']}>
            <Text>Adds a disabled attribute if true.</Text>
          </PropListItem>

          <PropListItem name="onClick" types={['func']}>
            <Text>Responds to the button click event.</Text>
          </PropListItem>

          <PropListItem name="buttonRef" types={['func']}>
            <Text>A ref passed to the native button element.</Text>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique string to control this button in automated tests.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            If you include an icon but no text, your button is automatically
            styled as icon-only.
          </li>
        </List>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
